<template src="@/../../../templates/brand/components/templates/MainNav.html"> </template>

<script>
import _ from 'lodash/fp'
import { mapState } from 'vuex'

export default {
  props: {
    whichNav: {
      type: String,
      default: 'get-it'
    }
  },
  data () {
    return {
      navVisible: false
    }
  },
  methods: {
    getUrl (path) {
      return this.$route.params.collection
        ? { 
            name: 'collectionCategoryProducts',
            params: { collection: this.$route.params.collection, cat1: _.uniq(path.split('/')).slice(1) }
          }
        : 
          path === '/'
          ? { 
              name: 'program',
              params: { program: this.$route.params.program }
            }
          : { 
              name: 'programCategoryProducts',
              params: { program: this.$route.params.program, cat1: _.uniq(path.split('/')).slice(1) }
            }
    },
    navClasses (item, index, items) {
      const _classes = []
      _classes.push(item.class || '')
      if (items.length === index - 1) _classes.push('last')
      return _classes.join(' ')
    }
  },
  computed: {
    ...mapState({
      nav: ({ Appui }) => {
        if (!Appui.homeConfig || !Appui.homeConfig.length) return {}
        const _main = Appui.homeConfig.find(
          i => i.type === 'Menu' && i.title === 'main'
        )
        try {
          JSON.parse(_main.json)
        } catch (e) {
          console.info(' :: MAIN MENU PARSE FAILED', e)
        }
        if (_main) return JSON.parse(_main.json)
        return {}
      }
    }),
    menuItems () {
      if (_.isEmpty(this.nav)) return []
      return this.nav[this.whichNav]
    },
    toggleNav () {
      return document.documentElement.clientWidth < 641
    }
  }
}
</script>

<style src="@/../../../templates/brand/components/styles/MainNav.css"></style>
